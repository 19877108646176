import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export default function Blog() {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  return (
    <div className="section">
      <div className="center">
        <div className="title text-center">BLOG</div>
        <div className="space-4" />
        <div className="paragraph">
          {title} vision is to gather the most interesting brands of Europe in
          one online billboard for eternity.
        </div>
        <div className="space-1" />
        <div className="paragraph">
          While its concrete mission is to reduce the air pollution in Europe by
          planting 100K trees in Europe from end profits of the project.
        </div>
        <div className="space-1" />
        <div className="paragraph">
          Therefore, {title} has also pledged to two specific goals, in order to
          achieve its mission:
          <ul>
            <li>Plant 100K trees in three most polluted cities in Europe</li>
            <li>
              And donate 100K euro in environmental grants in three most
              polluted cities in Europe
            </li>
          </ul>
        </div>
        <div className="space-1" />
        <div className="paragraph">
          <i>{`<<Nota bene>>`}</i> To assure every potential buyer, we have
          implemented a secure payment procces powered from Stripe which
          guarantees privacy and reliability. Payment transactions are SSL
          Encrypted!
        </div>

        <a href="https://stripe.com/" target="_blank" rel="noreferrer">
          <img src="/images/poweredByStripe.png" alt="Powered by Stripe" />
        </a>
      </div>
    </div>
  );
}
