import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Blog from "../components/blog";

export default function Faq() {
  return (
    <Layout>
      <SEO title="Blog" />
      <Blog />
    </Layout>
  );
}
